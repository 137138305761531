body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: normal;
  src: url(/static/media/NotoSansThai-Medium.9510797a.ttf);
  src: local("Noto Sans Thai"), local("Noto Sans Thai"), url(/static/media/NotoSansThai-Medium.9510797a.ttf) format("truetype"); }

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/NotoSans-Regular.f9493ba9.ttf);
  src: local("Noto Sans"), local("Noto Sans"), url(/static/media/NotoSans-Regular.f9493ba9.ttf) format("truetype"); }

html {
  font-size: 87.5%; }

/*14px*/
p {
  margin-bottom: 1rem; }

h1, h2, h3, h4, h5 {
  margin: 0;
  font-family: 'Noto Sans Thai', 'Noto Sans';
  font-weight: 400;
  line-height: 1.3; }

h1 {
  font-size: 3.052rem; }

h2 {
  font-size: 2.441rem; }

h3 {
  font-size: 1.953rem; }

h4 {
  font-size: 1.563rem; }

h5 {
  font-size: 1.25rem; }

small, .text_small {
  font-size: 0.8rem; }

body {
  background: white;
  font-family: 'Noto Sans Thai', 'Noto Sans';
  color: #161148;
  height: 100%; }

html, html > body > #root, html > body > #root > .App {
  height: 100%; }

.footer {
  background: #F9F9F9;
  font-size: 12px;
  color: #0B0134;
  border-top: 1px solid #E7E7E7;
  position: absolute;
  width: 100%; }

.content-text {
  font-size: 14px;
  color: #161148; }

.desc-text {
  font-size: 14px;
  color: #9B9A9C; }

.loading-page {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 100%; }

.borderless {
  border: none; }

a {
  color: #000000; }

a:hover {
  text-decoration: none;
  color: #676767; }

.container, .container-md, .container-sm {
  max-width: 1280px; }

.rbt-input-main.form-control.rbt-input {
  border-radius: 0; }

.modal-content {
  padding: 2% 5%; }

.modal-compare .modal-content {
  height: 400px; }

.color-white {
  color: white !important; }

.breadcrumb-link {
  color: #0B0134; }

.breadcrumb-link:hover {
  color: #6C54C8; }

.breadcrumb-item + .breadcrumb-item::before {
  content: ">"; }

.chart:hover {
  z-index: 10; }

.cookieNavbar {
  font-size: 13px;
  background: #EFEFEF;
  padding: 15px; }

.cookieButton {
  background: #33489F !important;
  color: #FFFFFF !important;
  border-radius: 1px;
  width: 130px;
  font-size: 13px; }

.cookieButton:hover {
  background: #4f5fa0 !important; }

#tooltip-img > .tooltip-inner {
  font-family: 'Noto Sans Thai', 'Noto Sans';
  width: 900px;
  max-width: 100%;
  color: #161148;
  background: #FFFFFF;
  box-shadow: -27px 20px 35px 0px rgba(0, 0, 0, 0.45);
  border-radius: 3.53027px;
  text-align: unset; }

.tooltip-text > .tooltip-inner {
  font-family: 'Noto Sans Thai', 'Noto Sans';
  width: 100%;
  max-width: 100%;
  color: #161148;
  background: #FFFFFF;
  box-shadow: -27px 20px 35px 0px rgba(0, 0, 0, 0.45);
  border-radius: 3.53027px;
  text-align: unset; }

.arrow::before {
  display: none !important; }

.tooltip.show {
  opacity: 1 !important; }

.middle-text {
  vertical-align: -webkit-baseline-middle; }

.isDisabled {
  cursor: not-allowed; }

.isDisabled > a {
  pointer-events: none;
  text-decoration: none;
  opacity: 0.5;
  -webkit-filter: brightness(50%);
          filter: brightness(50%); }

.bg-404 {
  /* The image used */
  background-image: url("/images/bg-404.jpg");
  /* Full height */
  height: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
  .bg-404 h1 {
    font-size: 9.5rem; }
  .bg-404 h3 {
    font-size: 2rem; }
  .bg-404 .container {
    padding-top: 8%; }

#tooltip-img {
  width: -webkit-fill-available; }

.setInputAddCompare .rbt {
  width: 100% !important; }

.card-year {
  width: -webkit-max-content;
  width: max-content;
  position: fixed;
  z-index: 10;
  top: 10px;
  right: 10px;
  white-space: nowrap;
  opacity: 0.9 !important;
  font-size: 16px; }

.card-year:hover {
  opacity: 1 !important; }

.card-year-title {
  font-size: 16px;
  text-align: center; }

.card-year-link:hover {
  color: #000000;
  font-weight: bold; }

.selection-year-icon {
  border: none;
  background: none; }

.selection-year-icon:focus {
  outline: none; }

.alert-info {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.banner-info {
  background: radial-gradient(69.83% 437.68% at -1.04% 101.96%, #305F8B 0%, #1E5284 100%); }

.input-search > .rbt-menu {
  width: -webkit-max-content !important;
  width: max-content !important;
  display: block !important; }

.rbt.input-search > .rbt-menu {
  display: block !important; }

.input-search-icon {
  background: white;
  padding: .25rem;
  border-radius: 0; }

.filter-icon {
  width: 5px;
  height: auto; }

.btn-dropdown-sort-strategic.show.dropdown > .dropdown-menu.show {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }

.btn:not(:disabled):not(.disabled) {
  overflow: hidden;
  text-overflow: ellipsis; }

.watermark-container {
  position: fixed;
  top: 40%;
  left: 0%;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 20 !important; }

.watermark-description {
  color: rgba(0, 0, 0, 0.1);
  font-size: 1.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg); }

.dropdown-column-chart {
  width: 270px;
  display: inline-block; }

.dropdown-column-chart {
  width: 270px;
  display: inline-block; }

.dropdown-column-chart > .btn {
  color: #000000;
  background-color: #f3f3f3;
  border-radius: 0;
  border: none;
  width: 100%; }

.dropdown-column-chart > button:hover,
.dropdown-column-chart > button:active,
.dropdown-column-chart > button:focus,
.dropdown-column-chart.show.dropdown > button:hover,
.dropdown-column-chart.show.dropdown > button:active,
.dropdown-column-chart.show.dropdown > button:focus {
  background-color: #5a6268;
  color: white;
  border-color: #5a6268; }

.dropdown-column-chart > .dropdown-menu.show {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center; }

#columnChart {
  z-index: 1; }

.profile-quadrant-chart-btn-group, .home-quadrant-chart-btn-group, .progressbar-btn-group {
  width: 100%;
  flex-wrap: wrap; }

.home-quadrant-chart-btn-group > .btn, .profile-quadrant-chart-btn-group > .btn, .progressbar-btn-group > .btn {
  background-color: #FFFFFF;
  color: #161148;
  border: 1px solid #eff0f6;
  border-radius: 0;
  margin-left: 0 !important;
  flex-basis: auto;
  font-size: 14px; }

.profile-quadrant-chart-btn-group > .btn:hover, .home-quadrant-chart-btn-group > .btn:hover, .progressbar-btn-group > .btn:hover {
  color: #FFFFFF;
  background-color: #0062cc;
  border-color: #0062cc; }

.progress-bar-performance {
  height: 7px; }

.progress-bar-potential {
  justify-content: flex-end;
  height: 7px; }

.bg-progressbar-index-0-score-1 {
  background-color: #F2D896; }

.bg-progressbar-index-0-score-2 {
  background-color: #F5D05D; }

.bg-progressbar-index-0-score-3 {
  background-color: #EFB208; }

.bg-progressbar-index-0-score-4 {
  background-color: #C89006; }

.bg-progressbar-index-0-score-5 {
  background-color: #A16E00; }

.bg-progressbar-index-1-score-1 {
  background-color: #E0E3F5; }

.bg-progressbar-index-1-score-2 {
  background-color: #B4BBEE; }

.bg-progressbar-index-1-score-3 {
  background-color: #6B81FF; }

.bg-progressbar-index-1-score-4 {
  background-color: #354CE5; }

.bg-progressbar-index-1-score-5 {
  background-color: #26328C; }

.bg-progressbar-index-2-score-1 {
  background-color: #DFF1A6; }

.bg-progressbar-index-2-score-2 {
  background-color: #CEDF4A; }

.bg-progressbar-index-2-score-3 {
  background-color: #9CAF0A; }

.bg-progressbar-index-2-score-4 {
  background-color: #5E7C12; }

.bg-progressbar-index-2-score-5 {
  background-color: #055816; }

.bg-progressbar-index-3-score-1 {
  background-color: #FFDCED; }

.bg-progressbar-index-3-score-2 {
  background-color: #FDA8CD; }

.bg-progressbar-index-3-score-3 {
  background-color: #E17DAE; }

.bg-progressbar-index-3-score-4 {
  background-color: #D0488B; }

.bg-progressbar-index-3-score-5 {
  background-color: #AC1E63; }

.bg-progressbar-index-4-score-1 {
  background-color: #C2D6D6; }

.bg-progressbar-index-4-score-2 {
  background-color: #91B6B6; }

.bg-progressbar-index-4-score-3 {
  background-color: #5A8787; }

.bg-progressbar-index-4-score-4 {
  background-color: #395656; }

.bg-progressbar-index-4-score-5 {
  background-color: #213131; }

.bg-progressbar-index-5-score-1 {
  background-color: #FD9BAE; }

.bg-progressbar-index-5-score-2 {
  background-color: #FC4A6E; }

.bg-progressbar-index-5-score-3 {
  background-color: #FB0E3D; }

.bg-progressbar-index-5-score-4 {
  background-color: #A10222; }

.bg-progressbar-index-5-score-5 {
  background-color: #4E0110; }

.bg-progressbar-index-6-score-1 {
  background-color: #EBFFFD; }

.bg-progressbar-index-6-score-2 {
  background-color: #C2FFF9; }

.bg-progressbar-index-6-score-3 {
  background-color: #99FFF5; }

.bg-progressbar-index-6-score-4 {
  background-color: #00F5DC; }

.bg-progressbar-index-6-score-5 {
  background-color: #00B8A5; }

.bg-progressbar-index-7-score-1 {
  background-color: #D6CEDE; }

.bg-progressbar-index-7-score-2 {
  background-color: #C2B6CD; }

.bg-progressbar-index-7-score-3 {
  background-color: #AA9ABA; }

.bg-progressbar-index-7-score-4 {
  background-color: #8F79A4; }

.bg-progressbar-index-7-score-5 {
  background-color: #665379; }

.btn-primary {
  background: none;
  border-radius: 0;
  border: none;
  color: #161148; }

.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: none;
  color: #16114879;
  box-shadow: none; }

.btn-submit, .btn-submit:disabled, .btn-submit:disabled:hover, .btn-submit:focus, .btn-cancel:focus {
  background-color: #33489F;
  color: #FFFFFF; }

.btn-submit:hover, .btn-cancel:hover {
  background-color: #33499fbe;
  color: #FFFFFF; }

.btn-cancel {
  color: #FFFFFF;
  background-color: #33489F; }

.div-compare {
  background-color: #FFFFFF; }

.btn-filter-strategic-deactive {
  width: 22%;
  font-size: 8px;
  font-weight: bold;
  border: 1px solid #33489F;
  box-sizing: border-box;
  border-radius: 1px;
  color: #ffffff;
  background: #33489F; }

.btn-filter-strategic-active {
  width: 22%;
  font-size: 8px;
  font-weight: bold;
  border: 1px solid #33489F;
  box-sizing: border-box;
  border-radius: 1px;
  color: #33489F;
  background: #FFFFFF; }

.btn-indicator-type {
  text-align: left;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid;
  border-color: #dcdcdc;
  background-color: #FFFFFF !important;
  color: #dcdcdc; }

.btn-indicator-type:hover, .btn-indicator-type:active, .btn-indicator-type:focus {
  color: #000000 !important;
  border-color: #000000 !important;
  background-color: #FFFFFF !important; }

.dropdown-card-select-year > button {
  color: #000000;
  background-color: #f3f3f3;
  border-radius: 0;
  border: none;
  width: 100%; }

.dropdown-card-select-year > button:hover,
.dropdown-card-select-year > button:focus,
.dropdown-card-select-year > button:active,
.dropdown-card-select-year.show.dropdown > button:hover,
.dropdown-card-select-year.show.dropdown > button:active,
.dropdown-card-select-year.show.dropdown > button:focus {
  background-color: #5a6268;
  color: white;
  border-color: #5a6268; }

.dropdown-card-select-year > .dropdown-menu.show {
  width: -webkit-fill-available; }

.university-link {
  font-size: 14px;
  color: #161148;
  word-break: "break-word"; }

.university-link:hover {
  text-decoration: none;
  color: #3B30AA; }

.progress {
  height: 0.5rem; }

.profile-image {
  width: 30px;
  height: auto; }

.card-compare {
  padding: 3%;
  height: 300px;
  justify-content: center; }

.height-strategic {
  height: 80px; }

.height-title {
  height: 80px; }

